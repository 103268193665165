@media screen and (max-width: 415px) {
  #features {
    padding: 20px;
    width: 100%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 100%;
  }

  #header {
    font-size: 10pt;
  }

  .intro-text h1 {
    font-size: 30pt;
  }
}

